<template>
  <v-main>
    <v-container v-if="lesson" class="">
      <div class="text-h5">{{ lesson.title }}</div>
      <div class="d-flex align-center mt-3">
        <span class="mr-2">Formular:</span>
        <v-select filled dense hide-details="auto" :items="forms" item-value="id" item-text="name" class="mr2-2"
                  v-model="lesson.formId" @change="updateFormId"></v-select>
      </div>
      <v-row class="mt-3 justify-center" dense>
        <v-col class="flex-grow-0 col-12 col-sm">
          <v-btn outlined class="" style="width: 100%" @click="createForm">
            <v-icon left>mdi-plus</v-icon>
            Neues Formular
          </v-btn>
        </v-col>
        <v-col class="flex-grow-0 col-12 col-sm">
          <v-btn outlined class="" style="width: 100%" :to="{name: 'FormEditor'}">
            <v-icon left>mdi-pencil</v-icon>
            Bearbeiten
          </v-btn>
        </v-col>
        <v-col class="flex-grow-0 col-12 col-sm">
          <v-btn outlined class="" style="width: 100%" @click="removeFormId">
            <v-icon left>mdi-link-off</v-icon>
            Verknüpfung aufheben
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>

export default {
  name: "LessonEditor",
  components: {},
  props: ["lessonId"],
  data: () => ({
    lesson: null,
    forms: []
  }),
  computed: {
  },
  mounted() {
    this.initialFetch();
  },
  methods: {
    initialFetch() {
      this.$store.dispatch("getRequest", ["lessons/" + this.lessonId, this.fetchLessonDataCallback]);
      this.fetchForms();
    },
    fetchForms() {
      this.$store.dispatch("getRequest", ["forms", this.fetchFormsCallback]);
    },
    fetchLessonDataCallback(data) {
      const indexInCourse = data["indexInCourse"];
      this.lesson = data["course"]["lessons"][indexInCourse]["lesson"];
    },
    fetchFormsCallback(data) {
      this.forms = data;
    },
    createForm() {
      const payload = {name: this.lesson.title}
      this.$store.dispatch("postRequest", ["forms/with-name", payload, this.createFormCallback]);
    },
    createFormCallback(data) {
      this.lesson.formId = data.id;
      this.fetchForms();
      this.updateFormId(data.id);
    },
    updateFormId(formId) {
      const payload = {formId: formId}
      this.$store.dispatch("putRequest", ["lessons/" + this.lessonId, payload]);
    },
    removeFormId() {
      this.lesson.formId = -1;
      this.updateFormId(-1);
    }
  }
};
</script>

<style scoped>

</style>